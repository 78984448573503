<template>
  <div class="dispatch-sidebar">
    <!-- START CONFLICT SCREEN -->
    <div v-show="showConflictDetail" class="dispatch-sidebar--wrapper">
      <div style="max-width: 435px">
        <div class="dispatch-sidebar--conflict-arrow">
          <a
            :style="`color: ${$cr.theme.grayDark}; height: 24px; margin-right: 8px;`"
            @click="setShowConflictDetail(false)"
          >
            <CRIcon view-box="0 0 24 24" :width="20" :height="20">
              arrow_back
            </CRIcon>
            Back To Reservation Assignment
          </a>
        </div>
        <div
          v-for="(conflict, i) in conflicts"
          :key="`conflict-${i}-${conflict.tripAssignmentId || ''}`"
          class="dispatch-sidebar--conflict-box"
        >
          <h4>{{ conflict.name }}</h4>
          <div class="dispatch-sidebar--conflict-details">
            <template v-if="conflict.conflictType === 'hard'">
              <CRIcon :width="24" :height="24" material color="error">
                error_color
              </CRIcon>
            </template>
            <template v-else>
              <CRIcon :width="24" :height="24" material color="yellow">
                error_color
              </CRIcon>
            </template>
            <div>
              <div class="dispatch-sidebar--conflict-description-header">
                <template v-if="conflict.conflictType === 'hard'">
                  Hard Conflict
                </template>
                <template v-else>Potential Conflict</template>
                - {{ conflict.conflictHeader }}
              </div>
              <p>{{ conflict.description }}</p>
            </div>
          </div>
          <div
            v-if="i < conflicts.length - 1"
            class="dispatch-sidebar--assignment-divider"
            style="margin-top: 40px"
          ></div>
        </div>
      </div>
    </div>

    <!-- START CONFIRMATION DETAIL -->
    <div v-show="showConfirmationDetail">
      <div class="dispatch-sidebar--confirmation-content">
        <div class="dispatch-sidebar--error-box">
          <v-layout
            row
            align-center
            class="dispatch-sidebar--error-box-content"
          >
            <v-flex xs3>
              <CRIcon material :width="40" :height="40" color="error">
                error_color
              </CRIcon>
            </v-flex>
            <v-flex>
              <p>
                Updating this reservation with your selected criteria would
                cause the hard conflicts below. Are you sure you want to
                proceed?
              </p>
            </v-flex>
          </v-layout>
        </div>
        <div
          v-for="(conflict, i) in conflicts"
          :key="`conflict-${i}-${conflict.tripAssignmentId || ''}`"
          class="dispatch-sidebar--confirmation-conflict-box"
        >
          <v-layout column>
            <v-layout row justify-start>
              <v-flex style="margin-left: 10px">
                <h4>{{ conflict.name }}</h4>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs3>
                <v-layout row justify-center>
                  <CRIcon
                    :width="24"
                    :height="24"
                    style="margin-left: -5px"
                    material
                    class="icon-error"
                  >
                    error_color
                  </CRIcon>
                </v-layout>
              </v-flex>
              <v-flex>
                <div class="dispatch-sidebar--confirmation-description-header">
                  Hard Conflict - {{ conflict.conflictHeader }}
                </div>
                <p>{{ conflict.description }}</p>
              </v-flex>
            </v-layout>
          </v-layout>
          <div
            v-if="i < conflicts.length - 1"
            class="dispatch-sidebar--assignment-divider"
            style="margin-top: 40px"
          ></div>
        </div>
      </div>
      <div class="cr-sidebar-dialog--button-spacer"></div>
      <div class="cr-sidebar-dialog--button-spacer"></div>
      <CRButton
        class="dispatch-sidebar--action-btn dispatch-sidebar--confirmation-cancel"
        color="white"
        @click="showConfirmationDetail = false"
      >
        <span class="primary-text">No, Go Back</span>
      </CRButton>
      <CRButton
        class="dispatch-sidebar--action-btn dispatch-sidebar--confirmation-submit"
        color="error"
        @click="saveReservation"
      >
        Yes, Update Reservation With Conflicts
      </CRButton>
    </div>
    <div
      v-show="
        !showConflictDetail && !showConfirmationDetail && !clearAssignments
      "
      class="dispatch-sidebar--assignments-wrapper"
    >
      <div
        class="dispatch-sidebar--assignments"
        :style="`background: ${$cr.theme.dispatchOffWhite}; height: inherit`"
      >
        <div v-for="(assignment, i) in model" :key="`assignment-group-${i}`">
          <!-- START VEHICLE SELECT -->
          <div class="dispatch-sidebar--assignment-input-box">
            <div>
              <label>{{ assignment.vehicleTypeLabel }}</label>
              <v-autocomplete
                placeholder="No Vehicle Selected"
                :items="
                  availableVehicles.length
                    ? availableVehicles.filter(
                        (v) => v.vehicleTypeId === assignment.vehicleTypeId
                      )
                    : availableVehicles
                "
                return-object
                :color="$cr.theme.grayBase"
                style="flex-grow: 0"
                solo
                flat
                hide-details
                item-text="name"
                item-value="vehicleId"
                :value="assignment.vehicle"
                @input="updateVehicleModel(assignment, i, $event)"
              >
                <template #item="{ item }">
                  <v-layout row justify-space-between>
                    <span>{{ item.name }}</span>
                    <span v-if="item.hardConflict">
                      <CRIcon material color="error">error_color</CRIcon>
                    </span>
                  </v-layout>
                </template>
              </v-autocomplete>
              <a
                :color="$cr.theme.primary"
                style="font-size: 13px"
                @click="updateVehicleModel(assignment, i, { vehicleId: null })"
              >
                Clear Assignment
              </a>
            </div>
            <v-tooltip top>
              <template #activator="action">
                <span
                  v-show="assignment.vehicle && assignment.vehicle.hardConflict"
                  style="cursor: pointer"
                  v-on="action.on"
                  @click="setShowConflictDetail(true)"
                >
                  <CRIcon color="error" material :height="36" :width="36">
                    error_color
                  </CRIcon>
                </span>
              </template>
              <span>Hard Conflict</span>
            </v-tooltip>
          </div>

          <!-- START DRIVER SELECT -->
          <div
            v-for="(driver, j) in assignment.driverAssignments"
            :key="`driver-selection-div-${j}`"
            class="dispatch-sidebar--assignment-input-box"
          >
            <div>
              <label :key="`driver-select-label-${j}`">Driver</label>
              <v-autocomplete
                :key="`driver-select-${j}`"
                :items="availableDrivers"
                label="Driver"
                placeholder="No Driver Selected"
                style="flex-grow: 0"
                solo
                flat
                :color="$cr.theme.grayBase"
                item-text="name"
                item-value="userId"
                return-object
                hide-details
                :value="assignment.driverAssignments[j].user"
                @input="updateDriverModel(assignment, i, j, $event)"
              >
                <template #item="{ item }">
                  <v-layout row justify-space-between>
                    <span>{{ item.name }}</span>
                    <span v-if="item.hardConflict">
                      <CRIcon material color="error">error_color</CRIcon>
                    </span>
                  </v-layout>
                </template>
              </v-autocomplete>
              <a
                :color="$cr.theme.primary"
                style="font-size: 13px"
                @click="updateDriverModel(assignment, i, j, {})"
              >
                Clear Assignment
              </a>
            </div>
            <!-- Conflict Icon -->
            <v-tooltip :key="`driver-tooltip-${j}`" top>
              <template #activator="action">
                <span
                  v-show="
                    assignment.driverAssignments[j].user &&
                    assignment.driverAssignments[j].user.hardConflict
                  "
                  style="cursor: pointer"
                  v-on="action.on"
                  @click="setShowConflictDetail(true)"
                >
                  <CRIcon color="error" material :height="36" :width="36">
                    error_color
                  </CRIcon>
                </span>
              </template>
              <span>Hard Conflict</span>
            </v-tooltip>
          </div>
          <div
            v-if="i < model.length - 1"
            class="dispatch-sidebar--assignment-divider"
          ></div>
        </div>
      </div>
      <div class="cr-sidebar-dialog--button-spacer"></div>
      <CRButton
        class="dispatch-sidebar--action-btn"
        color="primary"
        :disabled="assignmentsLoading || !assignmentsToUpdate.length"
        @click="validateReservationUpdates"
      >
        <span v-if="!assignmentsLoading">Update Reservation</span>
        <CRProgressCircular v-else :size="27" />
      </CRButton>
    </div>
    <div
      v-show="
        !showConflictDetail && !showConfirmationDetail && clearAssignments
      "
    >
      <div class="clear-assignments">
        <div class="clear-assignments--content">
          <p>
            Are you sure you would like to clear all assignments for these
            reservations?
          </p>
        </div>
        <div class="clear-assignments--button-spacer"></div>
        <div
          id="clear-assignments--submit-btn"
          class="clear-assignments--submit-btn"
          @click="callClearAssignments"
        >
          <span>Clear Assignments</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import {
  createTripAssignment,
  deleteTripAssignmentsWithIds,
  getTripAssignmentsForReservation,
} from '@/services/reservations'

export default {
  props: {
    reservations: {
      type: Array,
      default: () => [],
    },
    availableDrivers: {
      type: Array,
      default: () => [],
    },
    availableVehicles: {
      type: Array,
      default: () => [],
    },
    selectedModel: {
      type: Array,
      default: () => [],
    },
    clearAssignments: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: [],
      assignmentsToUpdate: [],
      resIds: [],
      showConfirmationDetail: false,
    }
  },
  computed: {
    ...mapGetters({
      assignmentsLoading: 'dispatchNew/assignmentsLoading',
      showConflictDetail: 'dispatchNew/showConflictDetail',
    }),
    conflicts() {
      let conflicts = []
      this.model.map((assignment) => {
        if (assignment.vehicle?.hardConflict) {
          conflicts.push({
            name: assignment.vehicle?.vehicleFullName,
            conflictType: 'hard',
            conflictHeader: 'Double Booked',
            description:
              'This vehicle has been assigned to a reservation that overlaps with the timing of this reservation.',
          })
        }

        assignment.driverAssignments.map((driverAssignment) => {
          if (driverAssignment.user?.hardConflict) {
            conflicts.push({
              name: `${driverAssignment.user?.firstName} ${driverAssignment.user?.lastName}`,
              conflictType: 'hard',
              conflictHeader: 'Double Booked',
              description:
                'This driver has been assigned to a reservation that overlaps with the timing of this reservation.',
            })
          }
        })
      })

      return conflicts
    },
  },
  async mounted() {
    this.model = this.selectedModel
    this.resIds = this.reservations.map((r) => r.item.reservationId)
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setLoading: 'dispatchNew/setLoading',
      setShowConflictDetail: 'dispatchNew/setShowConflictDetail',
    }),

    updateModel(payload) {
      let { assignment, index } = payload
      this.$set(this.model, index, assignment)

      this.assignmentsToUpdate = this.assignmentsToUpdate.filter(
        (assignment) => assignment.index !== index
      )
      this.assignmentsToUpdate.push({ ...assignment, index })
    },
    updateVehicleModel(existingAssignment, assignmentIndex, newVehicle) {
      let newAssignment = { ...existingAssignment, vehicle: { ...newVehicle } }
      this.model[assignmentIndex] = newAssignment
      this.updateModel({
        index: assignmentIndex,
        assignment: newAssignment,
      })
    },
    updateDriverModel(
      existingAssignment,
      assignmentIndex,
      driverIndex,
      newDriver
    ) {
      let { driverAssignments } = existingAssignment
      driverAssignments[driverIndex].user = newDriver
      driverAssignments[driverIndex].userId = newDriver.userId
      let newAssignment = { ...existingAssignment, driverAssignments }
      this.model[assignmentIndex] = newAssignment
      this.updateModel({
        index: assignmentIndex,
        assignment: newAssignment,
      })
    },
    async callClearAssignments() {
      let assignmentsToDelete = []

      await getTripAssignmentsForReservation({
        reservationIds: this.reservations.map((res) => res.item.reservationId),
      }).then(({ data: { vehicleAssignments } }) => {
        vehicleAssignments.forEach((va) => {
          assignmentsToDelete.push(va?.tripAssignmentId)
          va.driverAssignments.forEach((da) => {
            assignmentsToDelete.push(da?.tripAssignmentId)
          })
        })
      })

      if (assignmentsToDelete.length) {
        let deletionResponse = await deleteTripAssignmentsWithIds(
          assignmentsToDelete
        )
        if (!deletionResponse.data.successful) {
          throw deletionResponse
        }
      } else {
        this.showAlert({
          type: 'error',
          message: 'No assignments to delete.',
        })
      }
      this.$store.dispatch('app/closeDialog')
      EventBus.$emit('successful-clear-assignments', this.reservations)
    },
    validateReservationUpdates() {
      // Check that all drivers are assigned with a vehicle
      this.assignmentsToUpdate.map((va) => {
        va.driverAssignments.map((da) => {
          if (da?.user?.userId && !va?.vehicle?.vehicleId) {
            throw { message: 'All Drivers Must Be Assigned With a Vehicle' }
          }
        })
      })

      // Check that duplicate drivers are not assigned
      let driverIds = []
      this.model.map((va) => {
        let { driverAssignments } = va
        driverAssignments = driverAssignments
          .filter((d) => !!d.user?.userId)
          .map((d) => d?.user?.userId)
        const intersection = driverAssignments.filter((d) =>
          driverIds.includes(d)
        )
        if (intersection.length > 0) {
          throw {
            message:
              'The same driver has been assiged to more than one vehicle.',
          }
        } else {
          driverIds = [...driverIds, ...driverAssignments]
        }
      })

      // Check that there aren't any drivers or vehicles
      // assigned with hard conflicts
      if (this.conflicts.length > 0) {
        this.showConfirmationDetail = true
      } else {
        this.saveReservation()
      }
    },
    resetSavedReservation() {
      this.setLoading({ assignmentsLoading: false })
      this.assignmentsToUpdate = []
      this.$store.dispatch('app/closeDialog')
      EventBus.$emit('successful-assignment', this.resIds)
      return
    },
    getFirstAndLastStops(reservation) {
      let { stops } = reservation.item.trip
      if (!stops?.length) return {}
      let payload = {
        reservationId: reservation.item.reservationId,
      }

      let firstStop = stops[0]
      let lastStop = stops[stops.length - 1]
      let firstStopId = firstStop.stopId
      let lastStopId = lastStop.stopId
      let firstAddressId = reservation.item.trip?.firstAddressId
      let lastAddressId = reservation.item.trip?.lastAddressId

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!firstAddressId) {
        payload = { ...payload, firstAddressId }
      } else {
        payload = { ...payload, firstStopId, firstStop }
      }

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!lastAddressId) {
        payload = { ...payload, lastAddressId }
      } else {
        payload = { ...payload, lastStopId, lastStop }
      }

      return payload
    },
    async saveReservation() {
      try {
        this.setLoading({ assignmentsLoading: true })
        // this.validateReservationUpdates()

        // Create list of new assignments to create
        let assignmentsToCreate = this.assignmentsToUpdate.filter(
          (va) => !!va.vehicle?.vehicleId
        )

        if (assignmentsToCreate.length < 1) {
          this.setLoading({ assignmentsLoading: false })
          this.assignmentsToUpdate = []
          this.showAlert({
            type: 'error',
            message: 'Must have at least one valid assignment.',
          })
          return
        }

        this.reservations.forEach((res) => {
          let stopIds = this.getFirstAndLastStops(res)

          // Build the payload to hit /createAssignment for each new assignment
          assignmentsToCreate.map((vehicleAssignment, i) => {
            let payload = {
              ...stopIds,
              vehicleId: vehicleAssignment.vehicle.vehicleId,
              vehicleTypeId: vehicleAssignment.vehicleTypeId,
              driverAssignments: [],
              hardConflictOverride: true,
            }

            // Populate the driver assignment for each new assignment
            vehicleAssignment.driverAssignments.map((driverAssignment) => {
              if (driverAssignment?.user?.userId) {
                payload.driverAssignments.push({
                  userId: driverAssignment?.user?.userId,
                  hardConflictOverride: true,
                  ...stopIds,
                })
              }
            })

            // Create the new assignment from the payload, and if
            // this is the last assignment that needs to be created,
            // reset the reservation and complete the save.
            createTripAssignment(payload)
              .then((res) => {})
              .catch((e) => {
                console.log(e)
                this.showAlert({
                  type: 'error',
                  message: e.response?.data?.message || e.message,
                })
                this.setLoading({ assignmentsLoading: false })
              })
          })
        })
        this.resetSavedReservation()
      } catch (e) {
        console.log(e)
        this.showAlert({
          type: 'error',
          message: e.response?.data?.message || e.message,
        })
        this.setLoading({ assignmentsLoading: false })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.clear-assignments {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  position: relative;

  .clear-assignments--content {
    flex: 1;
    padding: 24px 48px;
  }

  .clear-assignments--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  .clear-assignments--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
}
.dispatch-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  h4 {
    font-size: 24px;
  }

  p {
    margin: 0;
  }

  &--conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 38px;
    }
  }

  &--conflict-details {
    display: flex;

    .cr-icon {
      min-width: 38px;
      padding-right: 10px;
    }

    p {
      color: $gray;
    }
  }

  &--confirmation-conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 60px;
    }

    p {
      margin-left: 5px;
    }
  }

  &--confirmation-description-header {
    font-weight: bold;
    margin-left: 5px;
  }

  &--conflict-description-header {
    font-weight: bold;
  }

  &--error-box {
    margin-bottom: 20px;

    &-content {
      background-color: $error-pale;
      padding: 20px 10px;
      border-radius: 10px;

      p {
        padding: 0 5px 0 15px;
      }
    }
  }

  &--conflict-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--gray-text {
    color: $dispatch-gray;
  }

  &--header-small {
    color: $dispatch-gray;
    font-size: 12px;
  }

  &--reservations-wrapper {
    flex: 1;
  }

  &--wrapper > div {
    margin: 40px;
  }
  &--assignment-input-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    svg {
      margin-bottom: 5px;
    }

    & > div {
      flex-basis: 85%;
    }
  }

  &--assignment-divider {
    min-width: 100%;
    height: 1px;
    margin-bottom: 34px;
    margin-top: 10px;
    background: $dispatch-gray;
  }

  &--assignments {
    margin: 0 !important;
    padding: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    border-radius: 0;
    transition: 0.1s all ease-in-out;

    &:disabled {
      background: $gray-light;
    }
  }

  &--confirmation-cancel {
    bottom: 71px;
    border: none !important;
  }

  &--confirmation-content {
    margin: 20px 40px;
  }
}

::v-deep {
  .v-timeline-item {
    padding-top: 7px;
  }

  .v-timeline-item__dot {
    height: 9px;
    width: 9px;
    left: 0px;
    box-shadow: none;
  }

  .v-timeline-item__inner-dot {
    height: 9px;
    width: 9px;
  }

  .v-timeline:before {
    background: transparent;
    border: none;
    border-right: 1px dashed black;
    top: 22px;
    height: calc(100% - 54px);
  }

  .v-timeline--dense:before {
    left: 3px;
  }

  .v-timeline {
    padding-top: 0;
  }

  .v-timeline-item__body {
    max-width: calc(100% - 21px);
  }

  .v-input__slot {
    background-color: $white !important;
    border: 1px solid $input-border-gray !important;
  }

  .theme--light.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark input {
    color: $gray-base !important;
  }
}

::v-deep .v-autocomplete input {
  color: $gray-base !important;
}

.primary-text {
  color: $primary;
}

.sidebar-link {
  color: $gray-dark;
  text-decoration: none;

  &:hover .dispatch-sidebar--customer-info-box {
    opacity: 0.8;
  }
}
</style>
